import { InteractionContainer } from './OldGeneralComponents';
import {
  LocalStorageItems,
  PageNames,
  setItem
} from '../utils/generalUtilities';
import { ModalProvider, useModal } from '@/hooks/useModal';
import { NewJPButton, Text2XL } from './GeneralComponents';
import { emailIcon, googleIcon } from '../assets/generalAssets';
import { gaLogin, gaSignUp } from '../lib/GoogleAnalytics';
import { initializeLocalStorage } from '../utils/autoProfileSetup';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import ForgetPasswordModal from './Modals/ForgetPasswordModal';
import Icon from '@/components/Shared/Icon';
import IconButton from './Shared/IconButton';
import Loading from './Loading';
import PartneroHelper from '@/models/PartneroHelper';
import React, { useEffect, useState } from 'react';
import User from '@/models/User';
import firebase from 'firebase/compat/app';
import locale from '../locales/en/NativeLanding';
import parse from 'html-react-parser';
import posthog from 'posthog-js';

interface AuthFormProps {
  title: string;
  subheading?: string;
  onClickEmailButton: () => void;
  isSignUpPage?: boolean;
}

const AuthForm = (props: AuthFormProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { openModal } = useModal();
  const router = useRouter();

  useEffect(() => {
    if (!firebase) return;
    firebase
      .auth()
      .getRedirectResult()
      .then(async (result) => {
        if (!result.user) return;
        await onAuthSuccess(result);
      })
      .finally(() => setIsLoading(false));
  }, [firebase]);

  const signinWithPopup = async () => {
    let provider = new firebase.auth.GoogleAuthProvider();
    const result = await firebase.auth().signInWithPopup(provider);
    await onAuthSuccess(result);
  };

  const loginWithGoogle = async () => {
    // if (isIOS || isSafariNonMobile()) {
    signinWithPopup();
    return;
    // }
    let provider = new firebase.auth.GoogleAuthProvider();
    await firebase
      .auth()
      .signInWithRedirect(provider)
      .catch((error) => {
        return toast.error(`${locale.toaster.misc_errors} ${error.message}`);
      });
  };

  const setupTrackingForGoogleAuth = (
    authRes: firebase.auth.UserCredential,
    isCreatingUser: boolean
  ) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      user_id: `${authRes.user.email}${authRes.user.uid}` //this number must be replaced with an actual User ID
    });
    if (isCreatingUser) {
      gaSignUp('Google');
      PartneroHelper.signup(
        authRes.user.uid,
        authRes.user.email,
        authRes.user.displayName || 'Unknown'
      );
      posthog.identify(authRes.user.uid);
      posthog.capture('account-signup', {
        method: 'Google'
      });
    } else {
      gaLogin('Google');
      posthog.identify(authRes.user.uid);
      posthog.capture('account-login', {
        method: 'Google'
      });
    }
  };

  const onAuthSuccess = async (
    result: firebase.auth.UserCredential
  ): Promise<any> => {
    if (!result || !result.user) return setIsLoading(false);
    setIsLoading(true);
    const accessToken = await firebase.auth().currentUser.getIdToken(true);

    let isCreatingUser = false;
    try {
      /* Using try catch as Firebase Google Sign Up and Login use the same function */
      const res = await User.createUser(accessToken);
      isCreatingUser = true;
    } catch (err) {}
    let user = result.user;
    toast.success(locale.toaster.login_success);
    setupTrackingForGoogleAuth(result, isCreatingUser);
    if (user.displayName)
      setItem(LocalStorageItems.DISPLAY_NAME, user.displayName);
    if (user.photoURL) setItem(LocalStorageItems.AVATAR, user.photoURL);
    setItem(LocalStorageItems.ACCESS_TOKEN, accessToken);
    await initializeLocalStorage(
      user,
      isCreatingUser ? PageNames.ONBOARDING : PageNames.MAGIC
    );
  };

  return (
    <>
      <InteractionContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="mt-20 flex w-full flex-col items-start">
              <Icon type="mi-users" size={24} className="mb-4 text-blue-600" />
              <Text2XL className="text-[24px] font-medium normal-case text-grey-800">
                {props.title}
              </Text2XL>
              <p className="mb-4 mt-2 text-left text-base font-normal text-grey-500">
                {props.subheading}
              </p>
            </div>
            <IconButton
              id={
                props.isSignUpPage
                  ? 'ga-google-signup-button'
                  : 'ga-google-login-button'
              }
              text={locale.common.continue_google}
              icon={googleIcon}
              onClick={loginWithGoogle}
            />
            <IconButton
              id={
                props.isSignUpPage
                  ? 'ga-email-signup-button'
                  : 'ga-email-login-button'
              }
              text={locale.common.continue_email}
              icon={emailIcon}
              onClick={props.onClickEmailButton}
            />
            {props.isSignUpPage ? (
              <div className="mt-6 text-grey-400">
                {parse(locale.common.agree_terms)}
              </div>
            ) : null}
            {!props.isSignUpPage ? (
              <div className="mt-2 text-grey-400">
                <NewJPButton
                  width="fit"
                  size="sm"
                  colorType="transparent"
                  onClick={() => {
                    openModal(<ForgetPasswordModal />, {
                      className: 'max-w-md'
                    });
                  }}
                  text={locale.common.password_forget_header}
                />
              </div>
            ) : null}
          </>
        )}
      </InteractionContainer>
    </>
  );
};

const AuthFormWrapper = (props: AuthFormProps) => <AuthForm {...props} />;
export default AuthFormWrapper;
