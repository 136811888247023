import { IconType } from '../Shared/Icon';
import { NewH4, NewJPButton, NewP1, NewP2 } from '../GeneralComponents';
import { toast } from 'react-toastify';
import JPInput from '../Shared/JPInput';
import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import locale from '../../locales/en/NativeLanding';

const ForgetPasswordModal = () => {
  const [formState, setFormState] = useState<'success' | 'loading' | 'init'>(
    'init'
  );
  const onResetPassword = async (email: string) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .finally(() => {
        toast.success(locale.common.password_reset_email_sent_unauth);
        setFormState('success');
      });
  };
  return (
    <div className="flex max-w-md flex-col items-start justify-center p-6">
      <div className="mt-4 flex w-full flex-col items-center">
        <NewH4 className="text-grey-800">
          {locale.common.password_forget_header}
        </NewH4>
        <NewP2 className="mb-2 mt-2">
          {locale.common.password_forget_desc}
        </NewP2>
        <form
          id="video-file-name"
          className="mt-6 w-full"
          onSubmit={(e) => {
            e.preventDefault();
            if (formState === 'success') return;
            setFormState('loading');
            const formData = new FormData(e.target as HTMLFormElement);
            const email = formData.get('email');
            onResetPassword(email as string);
          }}
        >
          <JPInput
            name="email"
            placeholder="Email"
            className="mb-4 w-[244px]"
          />
          <NewJPButton
            width="full"
            size="md"
            type={'submit'}
            disabled={formState !== 'init'}
            icon={
              formState === 'loading'
                ? IconType.Loading
                : formState === 'success'
                ? 'mi-check'
                : null
            }
            text={locale.common.password_reset_email_send}
          />
        </form>
      </div>
    </div>
  );
};

export default ForgetPasswordModal;
