import { Colours } from '../Colours';
import { H2 } from '../OldGeneralComponents';
import Image from 'next/image';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.button`
  align-items: center;
  display: flex;
  background: ${Colours.NewWhite};
  border-radius: 20px;
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 10px;
  justify-content: center;
  border: 0px;
  cursor: pointer;
  width: 100%;
  &:hover {
    transform: scale(1.05);
  }
`;

interface IconButtonProps {
  text: string;
  icon: any;
  id?: string;
  onClick: () => void;
  ref?: any;
  disabled?: boolean;
  style?: CSSProperties;
}

const IconButton = ({
  text,
  icon,
  onClick,
  ref,
  disabled,
  style,
  id
}: IconButtonProps): JSX.Element => (
  <button
    id={id}
    onClick={onClick}
    disabled={disabled}
    ref={ref}
    style={style}
    className="flex items-center 
    justify-center bg-white rounded-lg mb-2 
    text-md p-3 mx-2 w-full border border-gray-200 cursor-pointer text-gray-800
    transform transition-transform hover:scale-105"
  >
    <div className="flex items-center h-6 w-6 mr-3">
      <Image src={icon} alt="icon" />
    </div>
    <p>{text}</p>
  </button>
);

export default IconButton;
