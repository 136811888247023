import {
  LocalStorageItems,
  PageNames,
  SessionStorageItems,
  getItem,
  setSessionItem
} from '../utils/generalUtilities';
import { useRouter } from 'next/router';
import GoogleAuthForm from '../components/GoogleAuthForm';
import React, { useEffect } from 'react';
import locale from '../locales/en/NativeLanding';

const Login = () => {
  const router = useRouter();
  const { email } = router.query;

  useEffect(() => {
    const accessToken = getItem(LocalStorageItems.ACCESS_TOKEN);
    if (accessToken) {
      router.push(PageNames.ME);
    }
    if (!router.query?.email?.length) return;
    setSessionItem(SessionStorageItems.FROM_APPSUMO, 'true');
  }, [router.query]);

  return (
    <GoogleAuthForm
      title="Welcome back"
      subheading='Login to your account to continue'
      onClickEmailButton={() => {
        router.push({
          pathname: PageNames.EMAIL_LOGIN,
          query: { email }
        });
      }}
    />
  );
};

export default Login;
