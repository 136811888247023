declare global {
  interface Window {
    po: any;
  }
}

const PartneroHelper = {
  signup: async (id, email, name) => {
    window.po('customers', 'signup', {
      data: {
        key: id,
        name: name,
        email: email
      }
    });
  },
  sales: async () => {},
  refund: async () => {}
};

export default PartneroHelper;
